import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/levelup-logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/common/WhatsAppButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/FooterNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/SocialMedia.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/legal/AccessibilityWidget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogTrigger","DialogContent","DialogHeader","DialogTitle","DialogDescription"] */ "/vercel/path0/src/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/src/components/ui/separator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
